document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.open-package-popup').forEach(button => {
        button.addEventListener('click', () => {
            console.log('kj')
            const targetPopup = button.getAttribute('data-target');
            const popup = document.querySelector(`.popup[data-popup="${targetPopup}"]`);
            const popupBg = document.querySelector('.popup-bg');

            if (popup) {
                popup.classList.add('active');
                popupBg.classList.add('active');
            }
        });
    });

    document.querySelectorAll('.close, .popup-bg').forEach(element => {
        element.addEventListener('click', () => {
            document.querySelectorAll('.popup').forEach(popup => {
                popup.classList.remove('active');
            });
            document.querySelector('.popup-bg').classList.remove('active');
        });
    });
});

document.querySelectorAll('.popup').forEach(popup => {
    const radioTypeInputs = popup.querySelectorAll('.radio-type input[type="radio"]');
    radioTypeInputs.forEach(radio => {
        radio.addEventListener('change', () => {
            const selectedType = radio.value;

            popup.querySelectorAll('form').forEach(form => {
                if (form.getAttribute('data-type') === selectedType) {
                    form.style.display = 'flex';
                } else {
                    form.style.display = 'none';
                }
            });
        });
    });
});

document.querySelectorAll('.popup').forEach(popup => {
    const payTypeInputs = popup.querySelectorAll('.pay-type input[type="radio"]');

    const updateBlocks = (selectedType) => {
        popup.querySelectorAll('.form-data, .pay-data').forEach(block => {
            if (block.getAttribute('data-type') === selectedType) {
                block.style.display = 'block';
            } else {
                block.style.display = 'none';
            }
        });
    };

    payTypeInputs.forEach(radio => {
        radio.addEventListener('change', () => {
            const selectedType = radio.value;
            updateBlocks(selectedType);
        });
    });

    const resetToDefault = () => {
        const firstRadio = payTypeInputs[0];
        if (firstRadio) {
            firstRadio.checked = true;
            updateBlocks(firstRadio.value);
        }
    };

    popup.querySelector('.close').addEventListener('click', resetToDefault);
    document.querySelector('.popup-bg').addEventListener('click', resetToDefault);

    const arrowBack = popup.querySelector('.arrow_back');
    if (arrowBack) {
        arrowBack.addEventListener('click', resetToDefault);
    }
});

// Відкрити відповідний попап
document.querySelectorAll('.open-popup').forEach(button => {
    button.addEventListener('click', () => {
        const targetPopup = button.getAttribute('data-target');
        const popup = document.querySelector(`.popup[data-popup="${targetPopup}"]`);
        const popupBg = document.querySelector('.popup-bg');

        if (popup) {
            popup.classList.add('active');
            popupBg.classList.add('active');
        }
    });
});

document.querySelectorAll('.close, .popup-bg').forEach(element => {
    element.addEventListener('click', () => {
        document.querySelectorAll('.popup').forEach(popup => {
            popup.classList.remove('active');
        });
        document.querySelector('.popup-bg').classList.remove('active');
    });
});

document.querySelectorAll('.copy-text').forEach(button => {
    button.addEventListener('click', function() {
        const payData = this.closest('.pay-data');
        if (!payData) {
            console.error('Не знайдено контейнер із класом .pay-data');
            return;
        }

        const payDataTextElement = payData.querySelector('.pay-data-text');
        if (!payDataTextElement) {
            console.error('Не знайдено елемент із класом .pay-data-text');
            return;
        }

        const payDataText = payDataTextElement.innerText;

        navigator.clipboard.writeText(payDataText).then(() => {
            this.classList.add('success');
            setTimeout(() => this.classList.remove('success'), 5000);
        }).catch(err => {
            console.error('Неуспешно копіювання даних: ', err);
        });
    });
});

document.querySelectorAll('.copy-icon').forEach(icon => {
    icon.addEventListener('click', function() {
        const copyStringContainer = this.closest('.copy-string');
        if (!copyStringContainer) {
            console.error('Не знайдено контейнер із класом .copy-string');
            return;
        }

        const copyText = copyStringContainer.textContent.trim();

        navigator.clipboard.writeText(copyText).then(() => {
            this.classList.add('success');
            this.classList.add('copied');
            setTimeout(() => {
                this.classList.remove('success');
                this.classList.remove('copied');
            }, 5000);
        }).catch(err => {
            console.error('Неуспешно копіювання даних: ', err);
        });
    });
});
