import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';

var swiper1 = new Swiper(".cases-slider", {
    modules: [ Navigation ],
    loop: true,
    slidesPerGroup: 1,
    slidesPerView: 1,
    spaceBetween: 22,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        1100: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        600: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
    }
})

var swiper2 = new Swiper(".news", {
    modules: [ Navigation ],
    loop: true,
    slidesPerGroup: 1,
    slidesPerView: 1,
    spaceBetween: 22,
    navigation: {
        nextEl: ".swiper-button-next.first",
        prevEl: ".swiper-button-prev.first",
    },
    breakpoints: {
        1130: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        800: {
            slidesPerView: 3,
        },
        600: {
            slidesPerView: 2,
        },
    }
})

var swiper4 = new Swiper(".posts", {
    modules: [ Navigation ],
    slidesPerGroup: 1,
    slidesPerView: 1,
    spaceBetween: 22,
    loop: true,
    navigation: {
        nextEl: ".swiper-button-next.sec",
        prevEl: ".swiper-button-prev.sec",
    },
    breakpoints: {
        1130: {
            slidesPerView: 4,
            spaceBetween: 20,
            loop: false,
        },
        800: {
            slidesPerView: 3,
            loop: true,
        },
        600: {
            slidesPerView: 2,
            loop: true,
        },
    }
})

var swiper3 = new Swiper(".gallery-slider", {
    modules: [ Navigation ],
    slidesPerGroup: 1,
    slidesPerView: 1,
    spaceBetween: 50,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    }
})
