import WOW from "wow.js"

new WOW().init();

require("./packs/header");
require("./packs/accordion");
require("./packs/sliders");
require("./packs/general_popup_form");
require("./packs/package_popup_form");
require("./packs/utm");
require("./packs/autoremove");
